import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../commons/selectors/environment'
import {useSettings} from '../../hooks/use-settings'
import settingsParams from '../../settingsParams'
import {getEvents, hasMoreEvents, isMoreEventsLoading} from '../selectors/events'
import {useWidgetState} from './state-provider'

export const useEventsList = () => {
  const {experiments} = useExperiments()
  const loadMoreButtonImprovementsEnabled = experiments.enabled(ExperimentNames.LoadMoreButtonImprovements)
  const {get: getSetting} = useSettings()
  const pageLimit = getSetting(settingsParams.eventsPerPage) as number
  const events = useWidgetState(getEvents)
  const editor = useWidgetState(isEditor)
  const hasMore = useWidgetState(hasMoreEvents)
  const moreLoading = useWidgetState(isMoreEventsLoading)

  const showPartialList = loadMoreButtonImprovementsEnabled && editor

  return {
    events: showPartialList ? events.slice(0, pageLimit) : events,
    moreLoading,
    hasMore: hasMore || (showPartialList && events.length > pageLimit),
  }
}

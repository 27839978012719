import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import s from './full-date-location.scss'
import {FullDateLocationProps} from '.'

export const FullDateLocation: React.FC<FullDateLocationProps> = ({event, dataHook}) => {
  const {fullDate} = useEventDateInformation(event.id)
  const {
    allBreakpoints: {isListFullDateEnabled, isListLocationEnabled},
  } = useVisibilityStyles()
  const dateVisible = isListFullDateEnabled()
  const locationVisible = isListLocationEnabled()

  return (
    <div data-hook={dataHook ?? DH.fullDateLocation}>
      {dateVisible ? (
        <div className={s.date} data-hook="date">
          {fullDate}
        </div>
      ) : null}
      {locationVisible ? (
        <div className={s.location} data-hook="location">
          {getFormattedFullLocation(event)}
        </div>
      ) : null}
    </div>
  )
}
